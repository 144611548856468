import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { pythonApi } from "../api/pythonServerApi";
import { pagination } from "./pagination";
import { preloadMediaId } from "./preloadMediaId";

const rootReducer = combineReducers({
  [pythonApi.reducerPath]: pythonApi.reducer,
  pagination: pagination.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(pythonApi.middleware);
  },
  preloadedState: {
    pagination: preloadMediaId(),
  },
});

export type StateType = ReturnType<typeof rootReducer>;

setupListeners(store.dispatch);

export {
  useGetSrtByMediaIdMutation,
  useGetMp3ByMediaIdMutation,
  useGetMp3CountByMediaIdMutation,
  useSetStrByMediaIdMutation,
} from "../api/pythonServerApi";
