import classNames from "classnames";
import { ChangeEvent, useEffect, useRef } from "react";
import { handleInputFocus } from "../../constants/handleInput";
// import { handleInputFocus } from "../../constants/handleInput";
import { handleKey } from "../../constants/handleKey";
import { RevokeAction } from "../../constants/revokeAction";
import styles from "./Word.module.scss";

export interface IWord {
  name: string;
  value: string;
  startTime: string;
  sentTime: string;
  longTimeDiff: boolean;
  onPlayWord: (...params: any) => void;
  onPlaySent: (...params: any) => void;
  handleSwap: (index: number) => void;
  dataWordIndex: number;
  handleChangeWord: ({ index, value }: { index: number; value: string }) => void;
  handleWriteEndWord: ({
    index,
    char,
    isDelete,
  }: {
    index: number;
    char: string;
    isDelete?: boolean;
  }) => void;
  handleUnionWords: ({ index, dir }: { index: number; dir: string }) => void;
}

function Word({
  name,
  value,
  startTime,
  sentTime,
  longTimeDiff,
  onPlayWord,
  onPlaySent,
  handleSwap,
  dataWordIndex,
  handleChangeWord,
  handleWriteEndWord,
  handleUnionWords,
}: IWord) {
  useEffect(() => {
    handleKey.playStartWord = onPlayWord;
    handleKey.playStartSent = onPlaySent;
    handleKey.handleSwap = handleSwap;
    handleKey.handleWriteEndWord = handleWriteEndWord;
    handleKey.handleUnionWords = handleUnionWords;
  }, []);

  const inputClass = classNames(styles.inputWrapper, {
    [styles.firstWord]: value?.endsWith("."),
  });

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = `${(inputRef.current.value.length + 1) * 8}px`;
    }
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    RevokeAction.logAction({
      actionType: "changeWord",
      index: dataWordIndex,
      newValue: e.currentTarget.value,
      prevValue: value,
    });

    handleChangeWord({ index: dataWordIndex, value: e.currentTarget.value });
  };

  return (
    <input
      className={inputClass}
      ref={inputRef}
      type="text"
      name="transcribeInput"
      value={value}
      onClick={() => {
        handleKey.startWord = +startTime;
        handleKey.startSent = +sentTime;
      }}
      onFocus={() => {
        handleKey.startWord = +startTime;
        handleKey.startSent = +sentTime;
        handleKey.swapIndex = dataWordIndex;
        handleKey.tabCounter = dataWordIndex;
        handleKey.wordIndex = dataWordIndex;
        handleInputFocus(dataWordIndex);
      }}
      onBlur={() => {
        handleKey.startWord = 0;
        handleKey.startSent = 0;
        handleKey.swapIndex = -1;
        handleKey.wordIndex = -1;
      }}
      onChange={(e) => handleChange(e)}
    />
  );
}

export default Word;
