import { useEffect, useState } from "react";
import H5AudioPlayer from "react-h5-audio-player";
import { useSelector } from "react-redux";
import { handleKey } from "../constants/handleKey";
import { RevokeAction } from "../constants/revokeAction";
import { StateType, useGetSrtByMediaIdMutation } from "../store";
import { saveHandler } from "./SaveButton/SaveButton";
import Word from "./Word/Word";

function WordsList({
  onPlayWord,
  onPlaySent,
  playerRef,
}: {
  onPlayWord: any;
  onPlaySent: any;
  playerRef: React.MutableRefObject<H5AudioPlayer | null>;
}) {
  const { currentPage, mediaId, sessionToken, userId } = useSelector(
    (state: StateType) => state.pagination
  );
  const [getSrt, { data }] = useGetSrtByMediaIdMutation();
  const [dataWords, setDataWords] = useState<any[]>([]);
  const [isFirst, setIsFirst] = useState<boolean>(true);

  const inputs = document.getElementsByName("transcribeInput");

  useEffect(() => {
    if (mediaId && userId && isFirst && dataWords.length) {
      fetch(`${process.env.REACT_APP_BASE_URL}/media/changeWorkBy`, {
        method: "POST",
        body: JSON.stringify({
          mediaId,
          userId,
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });

      setIsFirst(false);
    }
  }, [dataWords]);

  useEffect(() => {
    mediaId && getSrt({ mediaId: mediaId, num: currentPage, sessionToken });
  }, [mediaId, currentPage]);

  const handleSwap = (index: number, isRevoke?: boolean) => {
    const currentIndex = index;
    if (!currentIndex) return;

    if (!~handleKey.swapIndex && !isRevoke) return;

    if (!isRevoke && ~currentIndex) {
      RevokeAction.logAction({
        actionType: "swap",
        index: currentIndex,
        newValue: "",
        prevValue: "",
      });
    }

    [dataWords[currentIndex], dataWords[currentIndex - 1]] = [
      { ...dataWords[currentIndex], fixed_words: dataWords[currentIndex - 1].fixed_words },
      { ...dataWords[currentIndex - 1], fixed_words: dataWords[currentIndex].fixed_words },
    ];
    setDataWords([...dataWords]);

    let localData = JSON.parse(localStorage.getItem("changes") || "{}");

    let changesBefore = [];
    if (index <= 3) {
      changesBefore = dataWords.slice(0, index + 4);
    } else {
      changesBefore = dataWords.slice(index - 3, index + 4);
    }

    let changesAfter = [];
    if (index <= 3) {
      changesAfter = dataWords.slice(1, index + 3);
    } else {
      changesAfter = dataWords.slice(index - 4, index + 3);
    }

    localData = {
      ...localData,
      [index - 1]: { id: index - 1, changes: changesBefore },
      [index]: { id: index, changes: changesAfter },
    };
    localStorage.setItem("changes", JSON.stringify(localData));

    handleKey.swapIndex = -1;
    if (!isRevoke) handleKey.tabCounter--;
  };

  useEffect(() => {
    if (data) {
      setDataWords(JSON.parse(data));
      handleKey.tabCounter = 0;
    }
  }, [data]);

  useEffect(() => {
    handleKey.handleChangeWord = handleChangeWord;
  }, [handleChangeWord]);

  useEffect(() => {
    const inputs = document.getElementsByName("transcribeInput");
    let playerInterval: NodeJS.Timeout | null = null;
    if (playerRef) {
      playerInterval = setInterval(() => {
        if (!playerRef.current?.audio.current?.paused) {
          let currentIndex = 0;
          currentIndex = dataWords.findIndex(
            (el) =>
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              el.start_time > playerRef?.current?.audio?.current?.currentTime
          );
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          inputs[currentIndex]?.select();
        }
      }, 100);
    }

    return () => {
      clearInterval(playerInterval as NodeJS.Timeout);
    };
  }, [playerRef, dataWords]);

  let changeTimeout: NodeJS.Timeout | null = null;

  function handleChangeWord({ index, value }: { index: number; value: string }) {
    if (!isFinite(index)) return;

    dataWords[index].fixed_words = value;
    setDataWords([...dataWords]);
    inputs[index]!.style.width = `${(dataWords[index].fixed_words.length + 1) * 8}px`;
    clearTimeout(changeTimeout as NodeJS.Timeout);
    let localData = JSON.parse(localStorage.getItem("changes") || "{}");

    changeTimeout = setTimeout(() => {
      let changes = [];
      if (index < 3) {
        changes = dataWords.slice(0, index + 4);
      } else {
        changes = dataWords.slice(index - 3, index + 4);
      }

      localData = {
        ...localData,
        [dataWords[index].index]: { id: dataWords[index].index, changes },
      };
      localStorage.setItem("changes", JSON.stringify(localData));
    }, 1000);
  }

  const handleWriteEndWord = ({
    index,
    char,
    isDelete,
    isRevoke,
  }: {
    index: number;
    char: string;
    isDelete?: boolean;
    isRevoke?: boolean;
  }) => {
    if (isDelete) {
      dataWords[index].fixed_words = "";
    } else {
      if (!isRevoke) {
        RevokeAction.logAction({
          actionType: "insert",
          index: index,
          newValue: `${dataWords[index].fixed_words + char}`,
          prevValue: dataWords[index].fixed_words,
        });
        dataWords[index].fixed_words += char;
      } else if (isRevoke) {
        dataWords[index].fixed_words = RevokeAction.getLastAction()?.prevValue;
      }
    }
    setDataWords([...dataWords]);
    inputs[index]!.style.width = `${(dataWords[index].fixed_words.length + 1) * 8}px`;
    let localData = JSON.parse(localStorage.getItem("changes") || "{}");
    let changes = [];
    if (index < 3) {
      changes = dataWords.slice(0, index + 4);
    } else {
      changes = dataWords.slice(index - 3, index + 4);
    }
    localData = {
      ...localData,
      [dataWords[index].index]: { id: dataWords[index].index, changes },
    };
    localStorage.setItem("changes", JSON.stringify(localData));
  };

  const handleUnionWords = ({
    index,
    dir,
    isRevoke,
  }: {
    index: number;
    dir: string;
    isRevoke?: boolean;
  }) => {
    if (!index) return;

    if (!dataWords[index - 1].fixed_words && !isRevoke) return;

    if (!isRevoke) {
      RevokeAction.logAction({
        actionType: "union",
        index,
        prevValue: dataWords[index - 1].fixed_words,
        newValue: "",
      });

      RevokeAction.logAction({
        actionType: "union",
        index,
        prevValue: dataWords[index].fixed_words,
        newValue:
          dir === "end"
            ? `${dataWords[index].fixed_words + " " + dataWords[index - 1].fixed_words}`
            : `${dataWords[index - 1].fixed_words + " " + dataWords[index].fixed_words}`,
      });

      if (dir === "end") {
        dataWords[index].fixed_words += " " + dataWords[index - 1].fixed_words;
      } else if (dir === "start") {
        dataWords[index].fixed_words = dataWords[index - 1].fixed_words + " " + dataWords[index].fixed_words;
      }
      dataWords[index - 1].fixed_words = "";
    } else if (isRevoke) {
      dataWords[index].fixed_words = RevokeAction.getLastAction()?.prevValue;
      RevokeAction.revokeLastAction();
      dataWords[index - 1].fixed_words = RevokeAction.getLastAction()?.prevValue;
      RevokeAction.revokeLastAction();
    }

    setDataWords([...dataWords]);
    inputs[index - 1]!.style.width = `${(dataWords[index - 1].fixed_words.length + 1) * 8}px`;
    inputs[index]!.style.width = `${(dataWords[index].fixed_words.length + 1) * 8}px`;

    let localData = JSON.parse(localStorage.getItem("changes") || "{}");

    let changes = [];
    if (index < 3) {
      changes = dataWords.slice(0, index + 4);
    } else {
      changes = dataWords.slice(index - 3, index + 4);
    }

    localData = {
      ...localData,
      [dataWords[index].index]: { id: dataWords[index].index, changes },
    };
    localStorage.setItem("changes", JSON.stringify(localData));
  };

  useEffect(() => {
    saveHandler.dataWords = dataWords;
  }, [dataWords]);

  return dataWords ? (
    <>
      {dataWords.map((fixed_words: any, index) => (
        <Word
          key={fixed_words.index}
          name={fixed_words.index}
          value={fixed_words.fixed_words}
          startTime={fixed_words.start_time}
          sentTime={fixed_words.sent_start}
          longTimeDiff={fixed_words.long_time_diff}
          onPlaySent={onPlaySent}
          onPlayWord={onPlayWord}
          handleSwap={handleSwap}
          dataWordIndex={index}
          handleChangeWord={handleChangeWord}
          handleWriteEndWord={handleWriteEndWord}
          handleUnionWords={handleUnionWords}
        />
      ))}
    </>
  ) : null;
}

export default WordsList;
