import { useEffect } from "react";
import { useSelector } from "react-redux";
import { StateType, useSetStrByMediaIdMutation } from "../../store";
import { toast } from "react-toastify";
import styles from "./SaveButton.module.scss";

interface ISaveHandler {
  dataWords: any[];
  saveChanges: () => void;
}

export const saveHandler: ISaveHandler = {
  dataWords: [],
  saveChanges() {},
};

function SaveButton() {
  const { mediaId, currentPage, sessionToken } = useSelector(
    (state: StateType) => state.pagination
  );
  const [setData, { isSuccess, isError, isLoading }] = useSetStrByMediaIdMutation();

  const handleClick = () => {
    // setData({ mediaId: mediaId, data: JSON.parse(localStorage.getItem("changes") || "{}") });
    // console.log(saveHandler.dataWords);
    setData({
      mediaId,
      data: JSON.stringify(saveHandler.dataWords),
      num: `${currentPage}`,
      sessionToken,
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setData({
        mediaId,
        data: JSON.stringify(saveHandler.dataWords),
        num: `${currentPage}`,
        sessionToken,
      });
    }, 600000);

    return () => {
      clearInterval(interval);
    };
  }, [mediaId, currentPage]);

  useEffect(() => {
    isError && toast("Произошла ошибка", { type: "error" });
    isLoading && toast("Сохранение...", { type: "info" });
    isSuccess && toast("Данные успешно сохранены", { type: "success" });
  }, [isError, isLoading, isSuccess]);

  return (
    <div className={styles.wrapper} onClick={() => mediaId && handleClick()}>
      Сохранить
    </div>
  );
}

export default SaveButton;
