import axios from "axios";
import classNames from "classnames";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { StateType, useGetMp3CountByMediaIdMutation } from "../../store";
import { pagination } from "../../store/pagination";
import styles from "./Pagination.module.scss";

function Pagination() {
  const { currentPage, mediaId, sessionToken } = useSelector(
    (state: StateType) => state.pagination
  );
  const [getCnt, { data }] = useGetMp3CountByMediaIdMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    mediaId && getCnt({ mediaId: mediaId, sessionToken });
  }, [mediaId]);

  useEffect(() => {
    if (mediaId && data) {
      axios.post(`${process.env.REACT_APP_BASE_URL}/media/setPageCount`, {
        mediaId,
        pageCount: data?.numPages,
      });
    }
  }, [mediaId, data]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);

  return data?.numPages ? (
    <div
      style={{
        display: "flex",
        gap: "5px",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
      }}
    >
      {Array(data?.numPages)
        .fill(1)
        .map((_, index) => (
          <div
            key={index + 1}
            className={classNames(styles.pageItem, {
              [styles.active]: index + 1 === currentPage,
              [styles.done]: data?.done_pages?.[index]?.status,
            })}
            onClick={() => dispatch(pagination.actions.setCurrentPage(index + 1))}
          >
            {index + 1}
          </div>
        ))}
    </div>
  ) : (
    <>Loading...</>
  );
}

export default Pagination;
