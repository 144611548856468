import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const pagination = createSlice({
  name: "pagination",
  initialState: {
    mediaId: "",
    userId: "",
    currentPage: 1,
    totalPage: 0,
    sessionToken: "",
    name: "",
  },
  reducers: {
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    setMediaId: (state, { payload }: PayloadAction<string>) => {
      state.mediaId = payload;
    },
    setTotalPage: (state, { payload }: PayloadAction<number>) => {
      state.totalPage = payload;
    },
  },
});
