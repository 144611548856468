import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const pythonApi = createApi({
  reducerPath: "python",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: "https://flask.dev.transcribe.torsunov.ru",
  }),
  endpoints(builder) {
    return {
      getSrtByMediaId: builder.mutation({
        query: ({ mediaId, num, sessionToken }) => {
          return {
            url: `/download_rus?file_name=${mediaId}/files_to_send/list_words_with_sent${num}.json`,
            method: "GET",
            headers: {
              Authorization: sessionToken,
            },
          };
        },
      }),
      getMp3ByMediaId: builder.mutation({
        query: ({ mediaId, num, sessionToken }) => {
          return {
            url: `/download_rus?file_name=${mediaId}/files_to_send/${num}.mp3`,
            method: "GET",
            responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
            cache: "no-cache",
            headers: {
              Authorization: sessionToken,
            },
          };
        },
      }),
      getMp3CountByMediaId: builder.mutation({
        query: ({ mediaId, sessionToken }) => {
          return {
            url: `/cnt_fls?media=${mediaId}`,
            method: "GET",
            headers: {
              Authorization: sessionToken,
            },
          };
        },
      }),
      setStrByMediaId: builder.mutation({
        query: ({ mediaId, data, num, sessionToken }) => {
          return {
            url: "/upload_fixed_rus",
            method: "POST",
            body: {
              media: mediaId,
              data,
              num,
            },
            headers: {
              Authorization: sessionToken,
            },
            responseHandler: (response: { text: () => any }) => response.text(),
          };
        },
      }),
    };
  },
});

export const {
  useGetSrtByMediaIdMutation,
  useGetMp3ByMediaIdMutation,
  useGetMp3CountByMediaIdMutation,
  useSetStrByMediaIdMutation,
} = pythonApi;
export { pythonApi };
