import { useEffect } from "react";
import Player from "./components/Player/Player";
import { handleKey } from "./constants/handleKey";
import "./style.scss";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Search from "./components/Search/Search";

function inactivityTime() {
  let time: NodeJS.Timeout | null = null;
  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeydown = resetTimer;
  function logout() {
    console.log("Inactive");
  }
  function resetTimer() {
    clearTimeout(time as NodeJS.Timeout);
    time = setTimeout(logout, 10000);
  }
}

window.onload = () => {
  inactivityTime();
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Player />
        <ToastContainer />
      </>
    ),
  },
  {
    path: "/search",
    element: <Search />,
  },
]);

function App() {
  useEffect(() => {
    document.addEventListener("keydown", handleKey.keyDown);
    if (!localStorage.getItem("changes")) {
      localStorage.setItem("changes", "{}");
    }

    return () => {
      document.removeEventListener("keydown", handleKey.keyDown);
    };
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
