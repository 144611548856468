// <-------START-------> Logic with hiding words behind the player <-------START------->

// const INITIAL_TOP = 105;
// const FIRST_TRANSFORM = 38;
// const TRANSFORM = 27;
// let firstTransform = false;
// let sumCount = 0;

// export const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
//   const wrapper = document.getElementById("wordListWrapper");
//   const bb = e.target.getBoundingClientRect().top;
//   const wb = wrapper!.style.transform
//     .split("")
//     .filter((i: any) => isFinite(i))
//     .join("");
//   const count = Math.ceil((bb - INITIAL_TOP) / TRANSFORM);

//   if (count >= 2) {
//     sumCount += count;
//     if (!firstTransform) {
//       wrapper!.style.transform = `translateY(-${FIRST_TRANSFORM}px)`;
//       firstTransform = true;
//     } else {
//       wrapper!.style.transform = `translateY(-${(sumCount - 1) * TRANSFORM}px)`;
//     }
//   } else if (count === -1) {
//     sumCount--;
//     wrapper!.style.transform = `translateY(${-+wb + TRANSFORM}px)`;
//   }
// };

// <-------END-------> Logic with hiding words behind the player <-------END------->

export const handleInputFocus = (index: number) => {
  const inputs = document.getElementsByName("transcribeInput");
  inputs.forEach((input, i) =>
    i < index ? (input.style.opacity = ".3") : (input.style.opacity = "1")
  );
};
