import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useEffect, useRef } from "react";
import { StateType, useGetMp3ByMediaIdMutation } from "../../store";
import WordsList from "../WordsList";
import H5AudioPlayer from "react-h5-audio-player";
import { handleKey } from "../../constants/handleKey";
import { useSelector } from "react-redux";
import Legend from "../Legend/Legend";
import styles from "./Player.module.scss";
import Pagination from "../Pagination/Pagination";
import SaveButton from "../SaveButton/SaveButton";
import { Loader } from "../Loader/Loader";

function Player() {
  const { currentPage, mediaId, sessionToken, name } = useSelector(
    (state: StateType) => state.pagination
  );
  const [getMp3, { data, isError, isLoading }] = useGetMp3ByMediaIdMutation();
  const playerRef = useRef<H5AudioPlayer | null>(null);

  useEffect(() => {
    mediaId &&
      getMp3({
        mediaId: mediaId,
        num: currentPage,
        sessionToken,
      });
  }, [mediaId, currentPage]);

  useEffect(() => {
    if (playerRef) {
      handleKey.playerRef = playerRef;
    }
  }, [playerRef]);

  useEffect(() => {
    return () => {
      handleKey.tabCounter = 0;
    };
  }, []);

  const playWord = (num: number) => {
    if (playerRef.current?.audio.current) {
      playerRef.current.audio.current.currentTime = num - 0.2;
      playerRef.current.audio.current.play();
    }
  };

  const playSent = (num: number) => {
    if (playerRef.current?.audio.current) {
      playerRef.current.audio.current.currentTime = num - 0.2;
      playerRef.current.audio.current.play();
    }
  };

  return data && !isError && !isLoading ? (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!isError && !isLoading && (
        <>
          <AudioPlayer
            progressUpdateInterval={50}
            preload="auto"
            defaultCurrentTime
            src={data}
            ref={playerRef}
            style={{ position: "fixed", zIndex: "2" }}
          />
          <SaveButton />
          <div className={styles.fileName}>{name}</div>
        </>
      )}
      <div style={{ display: "flex", flexDirection: "column" }} id="wordListWrapper">
        <div className={styles.wordListWrapper}>
          <WordsList onPlayWord={playWord} onPlaySent={playSent} playerRef={playerRef} />
        </div>
        <Pagination />
      </div>
      <Legend />
    </div>
  ) : (
    <Loader />
  );
}

export default Player;
