import H5AudioPlayer from "react-h5-audio-player";
import { RevokeAction } from "./revokeAction";

interface IHandleKey {
  tabCounter: number;
  playerRef: React.MutableRefObject<H5AudioPlayer | null> | null;
  playStartWord: ((str: number) => void) | null;
  playStartSent: ((str: number) => void) | null;
  handleSwap: ((index: number, isRevoke?: boolean) => void) | null;
  handleWriteEndWord:
    | (({
        index,
        char,
        isDelete,
        isRevoke,
      }: {
        index: number;
        char: string;
        isDelete?: boolean;
        isRevoke?: boolean;
      }) => void)
    | null;
  handleUnionWords:
    | (({ index, dir, isRevoke }: { index: number; dir: string; isRevoke?: boolean }) => void)
    | null;
  handleChangeWord:
    | (({ index, value, isRevoke }: { index: number; value: string; isRevoke?: boolean }) => void)
    | null;
  startWord: number;
  startSent: number;
  swapIndex: number;
  keyDown: any;
  firstFlag: boolean;
  wordIndex: number;
}

export const handleKey: IHandleKey = {
  tabCounter: 0,
  playerRef: null,
  playStartWord: null,
  playStartSent: null,
  handleSwap: null,
  handleWriteEndWord: null,
  handleUnionWords: null,
  handleChangeWord: null,
  startWord: 0,
  startSent: 0,
  swapIndex: -1,
  firstFlag: false,
  wordIndex: 0,
  keyDown: (e: KeyboardEvent) => {
    const inputs = document.getElementsByName("transcribeInput");
    switch (e.code) {
      case "Tab":
        e.preventDefault();
        if (e.shiftKey) {
          if (handleKey.tabCounter === 0 && !handleKey.firstFlag) {
            inputs[0]?.focus();
            handleKey.firstFlag = true;
          } else if (handleKey.tabCounter > 0 && handleKey.tabCounter < inputs.length) {
            handleKey.tabCounter--;
            inputs[handleKey.tabCounter]?.focus();
          }
        } else {
          if (handleKey.tabCounter === 0 && !handleKey.firstFlag) {
            inputs[0]?.focus();
            handleKey.firstFlag = true;
          } else {
            handleKey.tabCounter++;
            inputs[handleKey.tabCounter]?.focus();
          }
        }
        break;
      case "F1":
        e.preventDefault();
        if (handleKey.handleUnionWords && ~handleKey.wordIndex) {
          handleKey.handleUnionWords({ index: handleKey.wordIndex, dir: "start" });
        }
        break;
      case "F2":
        e.preventDefault();
        if (handleKey.handleUnionWords && ~handleKey.wordIndex) {
          handleKey.handleUnionWords({ index: handleKey.wordIndex, dir: "end" });
        }
        break;
      case "F3":
        e.preventDefault();
        if (handleKey.handleWriteEndWord && ~handleKey.wordIndex) {
          handleKey.handleWriteEndWord({ index: handleKey.wordIndex, char: "." });
        }
        break;
      case "F4":
        e.preventDefault();
        if (handleKey.handleWriteEndWord && ~handleKey.wordIndex) {
          handleKey.handleWriteEndWord({ index: handleKey.wordIndex, char: "," });
        }
        break;
      case "F5":
        e.preventDefault();
        if (handleKey.handleSwap) {
          handleKey.handleSwap(handleKey.swapIndex);
        }
        break;
      case "F6":
        e.preventDefault();
        handleKey.playStartSent?.(handleKey.startSent);
        break;
      case "F7":
        e.preventDefault();
        handleKey.playStartWord?.(handleKey.startWord);
        break;
      case "F8":
        e.preventDefault();
        handleKey.playerRef?.current?.audio.current?.pause();
        break;
      case "F9":
        e.preventDefault();
        if (handleKey.handleWriteEndWord && ~handleKey.wordIndex) {
          handleKey.handleWriteEndWord({ index: handleKey.wordIndex, char: "<br/>" });
        }
        break;
      case "F10":
        e.preventDefault();
        if (handleKey.playerRef?.current?.audio.current) {
          handleKey.playerRef.current.audio.current.playbackRate = 2.0;
          handleKey.playStartWord?.(handleKey.startWord);
        }
        break;
      case "F11":
        e.preventDefault();
        if (handleKey.playerRef?.current?.audio.current) {
          handleKey.playerRef.current.audio.current.playbackRate = 1.0;
          handleKey.playStartWord?.(handleKey.startWord);
        }
        break;
      case "F12":
        e.preventDefault();
        if (handleKey.playerRef?.current?.audio.current) {
          handleKey.playerRef.current.audio.current.playbackRate = 0.5;
          handleKey.playStartWord?.(handleKey.startWord);
        }
        break;
      case "KeyZ":
        if (e.ctrlKey) {
          e.preventDefault();
          if (RevokeAction.getLastAction()?.index) {
            switch (RevokeAction.getLastAction()?.actionType) {
              case "swap":
                handleKey.handleSwap?.(RevokeAction.getLastAction()?.index as number, true);
                RevokeAction.revokeLastAction();
                break;
              case "changeWord":
                handleKey.handleChangeWord?.({
                  index: RevokeAction.getLastAction()?.index as number,
                  value: RevokeAction.getLastAction()?.prevValue as string,
                  isRevoke: true,
                });
                RevokeAction.revokeLastAction();
                break;
              case "insert":
                handleKey.handleWriteEndWord?.({
                  index: RevokeAction.getLastAction()?.index as number,
                  char: "",
                  isDelete: false,
                  isRevoke: true,
                });
                RevokeAction.revokeLastAction();
                break;
              case "union":
                handleKey.handleUnionWords?.({
                  index: RevokeAction.getLastAction()?.index as number,
                  dir: "",
                  isRevoke: true,
                });
                break;
            }
          }
        }
        break;
    }
  },
};
