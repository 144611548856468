// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
type IActionType = "swap" | "union" | "insert" | "changeWord";

interface IAction {
  actionType: IActionType;
  prevValue: string;
  newValue: string;
  index: number;
}

export class RevokeAction {
  public static actions: IAction[] = [];
  public static lastWords: number[] = [];
  public static inputs = document.getElementsByName("transcribeInput");

  constructor() {}

  static getLastAction() {
    return this.actions.at(-1);
  }

  static revokeLastAction() {
    this.actions.pop();

    if (!this.lastWords.length) return;
    this.inputs = document.getElementsByName("transcribeInput");

    if (this.lastWords.at(-1)) {
      if (this.inputs[this.lastWords.at(-1)]) {
        this.inputs[this.lastWords.at(-1)].style.border = `2px solid ${
          this.inputs[this.lastWords.at(-1)].getAttribute("value").endsWith(".")
            ? "red"
            : "transparent"
        }`;
      }
      if (this.lastWords.at(-2)) {
        if (this.inputs[this.lastWords.at(-2)]) {
          this.inputs[this.lastWords.at(-2)].style.border = `2px solid ${
            this.inputs[this.lastWords.at(-2)].getAttribute("value").endsWith(".")
              ? "red"
              : "yellow"
          }`;
        }
      } else {
        this.inputs[this.lastWords.at(-1)].style.border = `2px solid ${
          this.inputs[this.lastWords.at(-1)].getAttribute("value").endsWith(".")
            ? "red"
            : "transparent"
        }`;
      }
    }
    this.lastWords.pop();
  }

  static logAction(action: IAction) {
    this.actions.push(action);

    this.lastWords.push(action.index);
    this.inputs = document.getElementsByName("transcribeInput");

    if (this.lastWords.at(-1)) {
      if (this.inputs[this.lastWords.at(-1)]) {
        this.inputs[this.lastWords.at(-1)].style.border = `2px solid ${
          action.newValue.endsWith(".") ? "red" : "yellow"
        }`;
      }
      if (this.lastWords.at(-2) && this.lastWords.at(-2) !== this.lastWords.at(-1)) {
        if (this.inputs[this.lastWords.at(-2)]) {
          this.inputs[this.lastWords.at(-2)].style.border = `2px solid ${
            this.inputs[this.lastWords.at(-2)].getAttribute("value").endsWith(".")
              ? "red"
              : "transparent"
          }`;
        }
      }
    }
  }
}
