export const preloadMediaId = () => {
  const urlParams = new URLSearchParams(location.search);
  const dataParams = Object.fromEntries(urlParams.entries());

  return {
    mediaId: dataParams.mediaId || "",
    userId: dataParams.userId || "",
    currentPage: dataParams.currentPage ? +dataParams.currentPage : 1,
    totalPage: 0,
    sessionToken: dataParams.sessionToken || "",
    name: dataParams.name || "",
  };
};
