import styles from "./Legend.module.scss";

function Legend() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.legendGroup}>
        <span className={styles.legendItem}>
          <h5>F1</h5>
          <p>Объединить предыдущее слово с текущим, вставив его перед текущим</p>
        </span>
        <span className={styles.legendItem}>
          <h5>F2</h5>
          <p>Объединить предыдущее слово с текущим, вставив его после текущего</p>
        </span>
        <span className={styles.legendItem}>
          <h5>F3</h5>
          <p>Добавить точку в конце слова</p>
        </span>
        <span className={styles.legendItem}>
          <h5>F4</h5>
          <p>Добавить запятую в конце слова</p>
        </span>
        <span className={styles.legendItem}>
          <h5>F5</h5>
          <p>Поменять местами с предыдущим</p>
        </span>
      </div>
      <div className={styles.legendGroup}>
        <span className={styles.legendItem}>
          <h5>F6</h5>
          <p>Начать аудио с начала предложения</p>
        </span>
        <span className={styles.legendItem}>
          <h5>F7</h5>
          <p>Начать аудио с текущего слова</p>
        </span>
        <span className={styles.legendItem}>
          <h5>F8</h5>
          <p>Стоп</p>
        </span>
      </div>
      <div className={styles.legendGroup}>
        <span className={styles.legendItem}>
          <h5>F9</h5>
          <p>{"Добавить тег <br/> в конце слова"}</p>
        </span>
        <span className={styles.legendItem}>
          <h5>F10</h5>
          <p>Скорость x2</p>
        </span>
        <span className={styles.legendItem}>
          <h5>F11</h5>
          <p>Скорость x1</p>
        </span>
        <span className={styles.legendItem}>
          <h5>F12</h5>
          <p>Скорость x1/2</p>
        </span>
      </div>
      <div className={styles.legendGroup}>
        <span className={styles.legendItem}>
          <h5>Tab</h5>
          <p>Следующее слово</p>
        </span>
        <span className={styles.legendItem}>
          <h5>Shift + Tab</h5>
          <p>Предыдущее слово</p>
        </span>
      </div>
    </div>
  );
}

export default Legend;
