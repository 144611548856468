import { useEffect, useState } from "react";
import styles from "./Search.module.scss";

interface IRes {
  docName: string;
  eng: string;
  rus: string;
}

const Search = () => {
  const [value, setValue] = useState<string>();
  const [response, setResponse] = useState<IRes[]>([]);

  useEffect(() => {
    const handler = setTimeout(async () => {
      if (value) {
        setResponse([]);
        const res = await fetch(
          "https://flask.dev.transcribe.torsunov.ru/search_es_rus?value=" + value
        );
        const body = await res.json();
        setResponse(body);
      } else {
        setResponse([]);
      }
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  useEffect(() => {
    console.log(response);
  }, [response]);

  return (
    <div className={styles.wrapper}>
      <h2>Введите слово или часть слова на русском языке для поиска по базе данных</h2>
      <input onChange={(e) => setValue(e.target.value)} placeholder="Перевод..." />
      {response.length ? (
        <>
          <div
            style={{
              marginTop: "15px",
              display: "flex",
              justifyContent: "space-evenly",
              gap: "15px",
              padding: "5px",
              width: "100%",
            }}
          >
            <p style={{ margin: 0 }}>Русский перевод</p>
            <p style={{ margin: 0 }}>Английский перевод</p>
            <p style={{ margin: 0 }}>Название файла</p>
          </div>
          {response.map((el) => (
            <div
              key={el.docName}
              style={{
                marginBottom: "15px",
                display: "flex",
                justifyContent: "space-between",
                gap: "15px",
                border: "1px solid black",
                padding: "5px",
                width: "100%",
              }}
            >
              <p style={{ width: "33%" }}>{el.rus}</p>
              <p style={{ width: "33%" }}>{el.eng}</p>
              <p style={{ width: "33%" }}>{el.docName}</p>
            </div>
          ))}
        </>
      ) : (
        "Данных не найдено"
      )}
    </div>
  );
};

export default Search;
